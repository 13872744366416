import {
  getProductTitle as calculateProductTitle,
  recalculateCustomizationPrice,
  calculateCustomizationIdentifier,
  getCustomSizeLabel as getCustomSizeLabelStr,
  getCustomUpholsteryLabel as getCustomUpholsteryLabelStr,
  getProductId as getProductIdStr
} from '~/helpers/customization'
import type { AttributeValueObject, ProductAlgolia, UnitObjectValue } from '~/types/product'
import type { CartItem, ShareCartItem, WishlistItem } from '~/types/item'
import type { Customization, CustomizationWithIdentifier, CustomizationWithPrice } from '~/types/customization'

export function useCustomization() {
  const getProductTitle = (title: string, customization?: Customization): string => {
    return calculateProductTitle(title, customization)
  }

  const getCustomizationWithPrice = (finalPrice: number, price: number, customization: CustomizationWithIdentifier): CustomizationWithPrice => {
    return recalculateCustomizationPrice(finalPrice, price, customization)
  }

  const getCustomizationIdentifier = (sku: string, customization: Customization) => {
    return calculateCustomizationIdentifier(sku, customization)
  }

  const getCustomSizeLabel = (width: number, length: number): string => {
    return getCustomSizeLabelStr(width, length)
  }

  const getCustomUpholsteryLabel = (code: string, values: UnitObjectValue<AttributeValueObject[]>): string => {
    return getCustomUpholsteryLabelStr(code, values)
  }

  const getProductId = (product: CartItem | WishlistItem | ShareCartItem | ProductAlgolia): string => {
    return getProductIdStr(product)
  }

  return {
    getProductTitle,
    getCustomizationWithPrice,
    getCustomizationIdentifier,
    getCustomSizeLabel,
    getCustomUpholsteryLabel,
    getProductId
  }
}
